/**
 * @file It contains scripts for user details/edit.
 */

import { mapActions, mapGetters } from "vuex"
import ThemisInputAppend from "@/components/shared/input-append"
import ThemisInputAppendOuter from "@/components/shared/input-append-outer"
import ThemisDecision from "@/components/shared/decision"

export default {
  name      : "User",
  components: { ThemisInputAppend, ThemisInputAppendOuter, ThemisDecision },
  data() {
    return {
      localUser              : undefined,
      isNameInputFocussed    : undefined,
      isEmailInputFocussed   : undefined,
      isPhoneInputFocussed   : undefined,
      isJobTitleInputFocussed: undefined,
      isGroupsInputFocussed  : undefined,
      isEmailDuplicate       : undefined,
      showMFADialog          : false,
      showDeleteDialog       : false
    }
  },
  computed: {
    ...mapGetters({
      users              : "users/users",
      groups             : "groups/groups",
      groupsOfUsers      : "users/groupsOfUsers",
      isUpdatingName     : "users/isUpdatingName",
      isNameUpdated      : "users/isNameUpdated",
      isUpdatingEmail    : "users/isUpdatingEmail",
      isEmailUpdated     : "users/isEmailUpdated",
      isPhoneUpdated     : "users/isPhoneUpdated",
      isUpdatingPhone    : "users/isUpdatingPhone",
      isJobTitleUpdated  : "users/isJobTitleUpdated",
      isUpdatingJobTitle : "users/isUpdatingJobTitle",
      isDisablingMFA     : "users/isDisablingMFA",
      isMFADisabled      : "users/isMFADisabled",
      isEnabledUpdated   : "users/isEnabledUpdated",
      isPasswordSent     : "users/isPasswordSent",
      isSendingPassword  : "users/isSendingPassword",
      isUserDeleted      : "users/isUserDeleted",
      isDeletingUser     : "users/isDeletingUser",
      emailUpdateError   : "users/emailUpdateError",
      isUpdatingEnabled  : "users/isUpdatingEnabled",
      isDefaultSsoEnabled: "configurations/isDefaultSsoEnabled"
    }),
    user() {
      return this.users.find(user => user.id === +this.$route.params.id)
    },
    groupsOfUser() {
      return this.groupsOfUsers[+this.$route.params.id]
    },
    userStatusLabel() {
      return this.localUser?.enabled ? this.$t("163") : this.$t("164")
    },
    isNameChanged() {
      return this.user?.name !== this.localUser?.name
    },
    isEmailChanged() {
      return this.user?.email !== this.localUser?.email
    },
    isPhoneChanged() {
      return this.user?.phone !== this.localUser?.phone
    },
    isJobTitleChanged() {
      return this.user?.jobTitle !== this.localUser?.jobTitle
    },
    isDeletingUserAllowed() {
      return (!this.localUser.enabled || this.isDefaultSsoEnabled) &&
        !this.localUser.supportUser
    },
    showDisableMfaButton() {
      return !this.localUser.supportUser && this.localUser.lastLogin && this.user.mfaEnabled
    },
    showResendPasswordButton() {
      return !this.localUser.lastLogin && !this.localUser.supportUser
    }
  },
  methods: {
    ...mapActions({
      updateUser    : "users/updateUser",
      updateGroups  : "users/updateRoles",
      resendPassword: "users/sendPassword",
      notify        : "shared/notify",
      deleteUser    : "users/deleteUser"
    })
  },
  watch: {
    user: {
      immediate: true,
      handler  : function(newValue) {
        if (!this.localUser) {
          this.localUser = { ...newValue }
        }
      }
    },
    "localUser.enabled": {
      deep   : true,
      handler: function(newValue) {
        if (this.user.enabled !== newValue) {
          this.updateUser({ id: this.user.id, enabled: newValue })
        }
      }
    },
    isPasswordSent: {
      handler: function(newValue) {
        if (newValue) {
          this.notify({
            type      : "success",
            text      : "504",
            parameters: {
              email: this.user.email
            }
          })
        }
      }
    },
    isDisablingMFA: {
      handler: function(newValue) {
        this.$DECISIONS.DISABLE_MFA.pActions[1].buttonProps.loading = newValue
      }
    },
    isMFADisabled: {
      handler: function(newValue) {
        if (newValue) {
          this.notify({
            type: "success",
            text: "505"
          })
          this.showMFADialog = false
        }
      }
    },
    isDeletingUser: {
      handler: function(newValue) {
        this.$DECISIONS.DELETE_USER.pActions[1].buttonProps.loading  = newValue
        this.$DECISIONS.DELETE_USER.pActions[0].buttonProps.disabled = newValue
      }
    },
    isUserDeleted: {
      handler: function(newValue) {
        if (newValue) {
          this.notify({
            type      : "success",
            text      : "442",
            parameters: {
              email: this.localUser.email
            }
          })
          this.showDeleteDialog = false
          this.$router.push({ name: "users" })
        }
      }
    },
    emailUpdateError: {
      immediate: true,
      handler  : function(newValue) {
        if (newValue) {
          if (newValue.field === "email" && newValue.type === "duplicate") {
            this.isEmailDuplicate = true
            return
          }
        }
        this.isEmailDuplicate = false
      }
    }
  }
}